<template>
  <div class="asset-allocation-report">
    <b-container class="main-gradient light" fluid>
      <b-container class="main-gradient-header-wrapper" :class="headerClass"> <!-- Breadcrumbs, tabs, title -->
        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters>
            <b-breadcrumb :items="breadcrumbItems" class="breadcrumb-light"></b-breadcrumb>
            <h1 class="text-left">{{ $t(translationPath + 'title') }}</h1>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper" no-gutters>
          <b-col no-gutters>
            <asset-allocation-report-submenu
              ref="Tabmenu"
              :active-tab="activeTab"
              @tab-switched="onTabSwitched"
            ></asset-allocation-report-submenu>
          </b-col>
        </b-row>
      </b-container>

      <b-row class="main-gradient-chart-wrapper" v-if="activeTab !== 'settings'"> <!-- Charts -->
        <div class="w-100 pb-5">
          <asset-allocation-report-chart-total v-if="activeTab === 'total'"
            :api-data="apiData"
          ></asset-allocation-report-chart-total>

          <asset-allocation-report-chart-account-groups v-if="activeTab === 'account-groups'"
            :api-data="apiData"
            :currency-symbol="currentCOA.currency ? currentCOA.currency : ''"
          ></asset-allocation-report-chart-account-groups>

          <asset-allocation-report-chart-accounts v-if="activeTab === 'accounts'"
            :api-data="apiData"
            :currency-symbol="currentCOA.currency ? currentCOA.currency : ''"
          ></asset-allocation-report-chart-accounts>

          <asset-allocation-report-chart-class-accounts v-if="activeTab === 'asset-class-accounts'"
            :api-data="apiData"
          ></asset-allocation-report-chart-class-accounts>
        </div>
      </b-row>
    </b-container>

    <b-container :class="{'main-content-wrapper': true, 'pt-0': true}"> <!-- Page content -->
      <asset-allocation-report-table-total v-if="activeTab === 'total'"
        :api-data="apiData"
      ></asset-allocation-report-table-total>

      <asset-allocation-report-table-account-groups v-if="activeTab === 'account-groups'"
        :api-data="apiData"
      ></asset-allocation-report-table-account-groups>

      <asset-allocation-report-table-accounts v-if="activeTab === 'accounts'"
        :api-data="apiData"
      ></asset-allocation-report-table-accounts>

      <asset-allocation-report-table-class-accounts v-if="activeTab === 'asset-class-accounts'"
        :api-data="apiData"
      ></asset-allocation-report-table-class-accounts>

      <asset-allocation-report-settings
        v-if="activeTab === 'settings'"
        :sections="sections"
        @sections-update="handleSectionUpdate"
        @settings-updated="handleSettingsUpdate"
      />
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import AssetAllocationReportSubmenu from './AssetAllocationReportSubmenu'
import AssetAllocationReportTableTotal from './AssetAllocationReportTableTotal'
import AssetAllocationReportTableAccountGroups from './AssetAllocationReportTableAccountGroups'
import AssetAllocationReportTableAccounts from './AssetAllocationReportTableAccounts'
import AssetAllocationReportTableClassAccounts from './AssetAllocationReportTableClassAccounts'
import AssetAllocationReportChartTotal from './AssetAllocationReportChartTotal'
import AssetAllocationReportChartAccountGroups from './AssetAllocationReportChartAccountGroups'
import AssetAllocationReportChartAccounts from './AssetAllocationReportChartAccounts'
import AssetAllocationReportChartClassAccounts from './AssetAllocationReportChartClassAccounts'
import AssetAllocationReportSettings from './AssetAllocationReportSettings'
import _ from 'lodash'

export default {
  name: 'AssetAllocationReport',
  mixins: [titleMixins],
  components: {
    AssetAllocationReportSubmenu,
    AssetAllocationReportTableTotal,
    AssetAllocationReportTableAccountGroups,
    AssetAllocationReportTableAccounts,
    AssetAllocationReportTableClassAccounts,
    AssetAllocationReportChartTotal,
    AssetAllocationReportChartAccountGroups,
    AssetAllocationReportChartAccounts,
    AssetAllocationReportChartClassAccounts,
    AssetAllocationReportSettings
  },
  data () {
    return {
      activeTab: 'total',
      translationPath: 'reports.balance.asset_allocation_report.',
      apiData: null,
      sections: [
        {
          label: 'common.assets',
          description: 'common.settings.descriptions.asset',
          model: 'asset',
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: []
            }
          ]
        },
        {
          label: 'common.liabilities',
          description: 'common.settings.descriptions.liability',
          model: 'liability',
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: []
            }
          ]
        },
        {
          label: 'common.title',
          model: 'title',
          value: null,
          required: true,
          disableButtons: true,
          child: [
            {
              type: 'input'
            }
          ]
        },
        {
          label: 'common.advanced_filter.description',
          model: 'description',
          value: null,
          show: true,
          disableButtons: true,
          child: [
            {
              type: 'textarea'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('reports._common.balance_reports'), to: '/reports/balance' },
        { text: '', active: true }
      ]
    },
    headerClass () {
      return {
        'pb-5': ['total', 'asset-class-accounts'].indexOf(this.activeTab) === -1 || !this.apiData
      }
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    }
  },
  methods: {
    handleSectionUpdate (value) {
      this.sections = value
    },
    handleSettingsUpdate (value) {
      this.handleSectionUpdate(value)
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description'), {
            title: this.$t('common.filter_settings_title'),
            variant: 'success',
            solid: true
          })
        })
    },
    onTabSwitched (tab) {
      this.activeTab = tab
      if (tab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: tab } })
      }
    },
    changeTab (tab) {
      if (['total', 'asset-class-accounts', 'assets-detailed-ext', 'account-groups', 'accounts', 'settings'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
        this.setPageTitle(this.titleMeta)
      }
    },
    loadData () {
      let ApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/balance/asset-allocation`
      const sectionsValue = _.flatten([...this.sections].filter((el) => el.model !== 'title' && el.model !== 'description').map((el) => el.value).filter((el) => el))
      const include = sectionsValue.length > 0 ? sectionsValue.join(',') : null
      if (include) {
        ApiEndpoint += `?include=${include}`
      }
      return axios.get(ApiEndpoint)
        .then((response) => {
          this.apiData = response.data.data
          if (Object.prototype.hasOwnProperty.call(this.apiData, 'accounts')) {
            this.apiData.accounts.sort((a, b) => {
              if (a.group_id + a.title > b.group_id + b.title) {
                return 1
              }
              return -1
            })
          }
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        })
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    this.changeTab(this.$route.params.tab)
  },
  watch: {
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    },
    titleMeta: {
      handler: function () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.asset-allocation-report {
  width: 100%;
  .table-heading, .spirecta-simple-table {
    h2, a {
      color: #000;
    }
  }
  .tr-subtotal {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    font-weight: bold;
  }
  .tr-total {
    border-top: 1px solid #000;
    border-bottom: 2px solid #000;
    font-weight: bold;
  }
  .table-wrap-total {
    thead {
      display: none;
    }
  }
  .asset-classes-table {
    thead {
      display: none;
    }
    tr:first-child {
      td {
        border-top: none;
      }
    }
    .tr-group {
      td {
        background-color: #fff;
        padding-top: 25px;
        padding-bottom: 10px;
        font-size: 120%;
      }
    }
    .tr-heading {
      font-size: 14px;
      line-height: 18px;
      text-align: right;
      color: #74788D;
    }
    .td-val {
      width: 100px;
    }
    .tr-account {
      .td-title {
        font-weight: 400;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .td-amount {
      width: 25%;
    }
    .td-percentage {
      width: 17%;
    }
  }
}
</style>
