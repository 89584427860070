<template>
  <b-container fluid class="asset-allocation-report-table px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'table_title')}} </h2>
          </template>

            <b-col class="col-md-8">
              <i18n tag="p" :path="translationPath + 'description'">
                <template #account_plan>
                  <b-link :to="{ name: 'account-plan-assets-overview'}">{{ $t('reports.other._common.account_plan_report')}}</b-link>
                </template>
              </i18n>
            </b-col>

            <b-table class="spirecta-simple-table income-accounts-table asset-classes-table asset-class-report-accounts-table" show-empty hover responsive striped
                     stacked="md"
                     :items="rows"
                     :fields="fieldValues"
                     :tbodyTrClass="trClass"
                     :busy="busy"
                     :empty-text="$t(translationPath + 'table_empty_text')"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>

              <template v-slot:cell(title)="row">
                <template v-if="row.item.type === 'row-group'">
                  <b-link :to="'/reports/performance/account-groups/' + row.item.id">{{ row.value }}</b-link>
                </template>
                <template v-else-if="row.item.type === 'row-account'">
                  <span class="px-2 account_title">&ndash;</span>
                  <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'">{{ row.value }}</b-link>
                </template>
                <template v-else>
                  {{ row.value }}
                </template>
              </template>

              <template v-slot:cell(amount)="row">
                <template v-if="currentCOA.locale && row.item.type === 'row-account'">
                  {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true , 0) }}
                </template>
                <template v-else-if="currentCOA.locale && row.item.type === 'row-group'">
                  <div class="tr-heading">{{row.field.label}}</div>
                </template>
                <template v-else>&nbsp;</template>
              </template>

              <template v-for="(field, index) in [...allocationFields, 'sum']" :slot="'cell(' + field + ')'" slot-scope="row">
                <template v-if="row.item.type === 'row-account'">
                  {{ row.value }} %
                </template>
                <template v-else>
                  <div v-bind:key="index" class="tr-heading">{{row.field.label}}</div>
                </template>
              </template>
            </b-table>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AssetAllocationReportTableMixin from './AssetAllocationReportTableMixin'

export default {
  name: 'AssetAllocationReportTableAccounts',
  mixins: [AssetAllocationReportTableMixin],
  data () {
    return {
      translationPath: 'reports.balance.asset_allocation_report.tab_accounts.',
      rows: [],
      busy: true
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title'), class: 'td-title' },
        { key: 'equities', label: this.$t(this.translationPathCommon + 'equities'), class: 'text-right td-val' },
        { key: 'fixed_income', label: this.$t(this.translationPathCommon + 'fixed_income'), class: 'text-right td-val' },
        { key: 'cash', label: this.$t(this.translationPathCommon + 'cash'), class: 'text-right td-val' },
        { key: 'gold', label: this.$t(this.translationPathCommon + 'gold'), class: 'text-right td-val' },
        { key: 'alternatives', label: this.$t(this.translationPathCommon + 'alternatives'), class: 'text-right td-val' },
        { key: 'other', label: this.$t(this.translationPathCommon + 'other'), class: 'text-right td-val' },
        { key: 'not_defined', label: this.$t(this.translationPathCommon + 'not_defined'), class: 'text-right td-val' },
        { key: 'amount', label: this.$t(this.translationPathCommon + 'amount'), class: 'text-right td-val td-amount' }

      ]
    }
  },
  methods: {
    makeRows () {
      this.busy = true
      this.rows = []
      if (this.apiData === null) {
        return false
      }
      if (this.apiData.length === 0) {
        this.busy = false
        return false
      }

      for (const groupId in this.apiData.account_groups) {
        const rowGroup = {
          title: this.apiData.account_groups[groupId].title,
          id: groupId,
          type: 'row-group'
        }
        this.rows.push(rowGroup)

        this.apiData.account_groups[groupId].accounts.map((account) => {
          const rowAccount = {
            title: account.title,
            id: account.id,
            type: 'row-account',
            amount: account.balance,
            equities: account.equity_percentage,
            fixed_income: account.fixed_income_percentage,
            cash: account.cash_percentage,
            gold: account.gold_percentage,
            alternatives: account.alternatives_percentage,
            other: account.other_percentage,
            not_defined: account.not_defined_percentage,
            sum: account.equity_percentage + account.fixed_income_percentage + account.cash_percentage + account.gold_percentage + account.alternatives_percentage + account.other_percentage + account.not_defined_percentage
          }
          this.rows.push(rowAccount)
        })
      }

      this.busy = false
    }
  }
}
</script>

<style lang="scss">
  .asset-class-report-accounts-table{
    .td-amount{font-weight: 600}
    .tr-group td{ }
  }
</style>
