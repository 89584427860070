<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="total" :class="{selected: activeTab === 'total'}"  @click="onTabClick">
          {{ $t(translationPath + 'total') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="asset-class-accounts" :class="{selected: activeTab === 'asset-class-accounts'}"  @click="onTabClick">
          {{ $t(translationPath + 'asset_class_accounts') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="account-groups" :class="{selected: activeTab === 'account-groups'}"  @click="onTabClick">
          {{ $t(translationPath + 'account_groups') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="accounts" :class="{selected: activeTab === 'accounts'}"  @click="onTabClick">
          {{ $t(translationPath + 'accounts') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="settings" :class="{selected: activeTab === 'settings'}"  @click="onTabClick">
          {{ $t('common.header.header_menu.user_menu.settings') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AssetAllocationReportSubmenu',
  props: {
    activeTab: {
      type: String,
      default: 'total'
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.asset_allocation_report.tab_titles.'
    }
  },
  methods: {
    onTabClick () {
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.$emit('tab-switched', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/submenu.scss';
</style>
