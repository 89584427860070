<template>
  <b-container fluid class="mb-5">
    <template v-if="!chartData">
      <empty-graph :message="emptyGraphMessage" />
    </template>
    <template v-else>
      <treemap-nested v-if="chartData !== null" :data="chartData" />
    </template>
  </b-container>
</template>

<script>
import AssetAllocationReportChartMixin from './AssetAllocationReportChartMixin'
import treemapNested from '@/components/d3charts/treemap-nested/treemap-nested.vue'
import EmptyGraph from '@/components/common/EmptyGraph'

export default {
  name: 'AssetAllocationReportchartClassAccounts',
  mixins: [AssetAllocationReportChartMixin],
  components: { treemapNested, EmptyGraph },
  computed: {
    chartData () {
      if (this.apiData === null || this.apiData.length === 0) {
        return null
      }

      const data = []
      this.allocationFields.map((assetClass, index) => {
        const classItem = {
          label: this.$t(this.translationPathCommon + assetClass),
          color: this.aColors[index],
          textColor: '#000',
          amount: 0,
          // amount: this.apiData.asset_class_accounts[assetClass].reduce((a, b) => {
          // let prop = assetClass === 'equities' ? 'equity_amount' : assetClass + '_amount'
          // return b[prop] === null ? a : a + b[prop]
          // }, 0),
          children: []
        }
        this.apiData.asset_class_accounts[assetClass].map((account) => {
          const amountProp = assetClass === 'equities' ? 'equity_amount' : assetClass + '_amount'
          const accountItem = {
            label: account.title,
            color: this.aColors[index],
            textColor: '#000',
            amount: account[amountProp]
          }

          if (accountItem.amount > 0) {
            classItem.children.push(accountItem)
          }
        })
        data.push(classItem)
      })

      return data
    },
    emptyGraphMessage () {
      return this.$t('incomes_expenses.accounts.view.no_transaction_no_chart')
    }
  }
}
</script>
