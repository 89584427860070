<template>
  <b-container fluid class="asset-allocation-report-table px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'table_title')}} </h2>
          </template>

            <b-col class="col-md-8">
              <p>{{ $t(translationPath + 'description') }}</p>
            </b-col>

            <template v-for="(group, index) in rows">
              <div :key="index" class="mt-4" :class="'table-wrap-' + index">
                <b-col cols="12" class="table-content px-0">
                  <b-table class="spirecta-simple-table income-accounts-table asset-allocation-account-groups-table" show-empty hover responsive striped
                    stacked="sm"
                    :items="group.rows"
                    :fields="fieldValues"
                    :tbody-tr-class="trClass"
                    :busy="busy"
                  >
                    <template v-slot:table-busy>
                      <loader/>
                    </template>

                    <template v-slot:head(allocation)>
                      <h3><b-link :to="'/reports/performance/account-groups/' + group.id">{{ group.title }}</b-link></h3>
                    </template>

                    <template v-slot:cell(allocation)="row">
                      <template v-if="allocationFields.indexOf(row.item.allocation) !== -1">
                        <span class="font-weight-normal">{{ $t(translationPathCommon + row.value) }}</span>
                      </template>
                      <template v-else>{{ $t('common.' + row.value) }}</template>
                    </template>

                    <template v-slot:cell(amount)="row">
                      <template v-if="currentCOA.locale">
                        {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true , 0) }}
                      </template>
                    </template>

                    <template v-slot:cell(percentage)="row">
                      {{ row.value }} %
                    </template>
                  </b-table>
                </b-col>
              </div>
            </template>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AssetAllocationReportTableMixin from './AssetAllocationReportTableMixin'

export default {
  name: 'AssetAllocationReportTableAccountGroups',
  mixins: [AssetAllocationReportTableMixin],
  data () {
    return {
      translationPath: 'reports.balance.asset_allocation_report.tab_account_groups.',
      rows: {},
      busy: true
    }
  },
  methods: {
    makeRows () {
      this.busy = true
      this.rows = {}
      if (this.apiData === null) {
        return false
      }
      if (this.apiData.length === 0) {
        this.busy = false
        return false
      }

      const totalRow = {
        allocation: 'total', amount: 0, percentage: 0, type: 'row-total'
      }
      for (const groupId in this.apiData.account_groups) {
        const group = this.apiData.account_groups[groupId]
        let totalPercentage = 0
        this.allocationFields.map((field) => {
          totalPercentage = parseFloat(parseFloat(totalPercentage) + parseFloat(group.percentages[field])).toFixed(1)
        })
        const item = {
          title: group.title,
          id: group.id,
          rows: [
            { allocation: 'equities', amount: group.sums.equities, percentage: group.percentages.equities, type: 'row' },
            { allocation: 'fixed_income', amount: group.sums.fixed_income, percentage: group.percentages.fixed_income, type: 'row' },
            { allocation: 'cash', amount: group.sums.cash, percentage: group.percentages.cash, type: 'row' },
            { allocation: 'gold', amount: group.sums.gold, percentage: group.percentages.gold, type: 'row' },
            { allocation: 'alternatives', amount: group.sums.alternatives, percentage: group.percentages.alternatives, type: 'row' },
            { allocation: 'other', amount: group.sums.other, percentage: group.percentages.other, type: 'row' },
            { allocation: 'not_defined', amount: group.sums.not_defined, percentage: group.percentages.not_defined, type: 'row' },
            { allocation: 'subtotal', amount: group.total_sum, percentage: totalPercentage, type: 'row-subtotal' }
          ]
        }

        totalRow.amount += item.rows[item.rows.length - 1].amount
        totalRow.percentage = 100

        this.rows[groupId] = item
      }

      this.rows.total = { title: '', id: null, rows: [totalRow] }
      this.busy = false
    }
  }
}
</script>

<style lang="scss">
.asset-allocation-account-groups-table{
  thead th{vertical-align: bottom;padding-bottom:10px;font-size:90%;font-weight: 400;}
}
</style>
