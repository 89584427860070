<template>
  <b-container fluid class="mb-5">
    <template v-if="!chartData">
      <empty-graph :message="emptyGraphMessage" />
    </template>
    <template v-else>
      <bar-chart v-if="chartData !== null"
        :chart-data="chartData"
        :options="stackedChartOptions"
        :chart-id="'asset-allocation-report-chart-accounts'"
        :width="100"
        :height="400"
        :plugins="[]"
      >
      </bar-chart>
    </template>
  </b-container>
</template>

<script>
import AssetAllocationReportChartMixin from './AssetAllocationReportChartMixin'
import BarChart from '@/components/chartjs/BarChart'
import EmptyGraph from '@/components/common/EmptyGraph'

export default {
  name: 'AssetAllocationReportChartAccounts',
  mixins: [AssetAllocationReportChartMixin],
  components: { BarChart, EmptyGraph },
  props: ['currency-symbol'],
  computed: {
    chartData () {
      if (!this.apiData || this.apiData.length === 0) {
        return null
      }

      const datasets = []
      let prop = ''

      this.allocationFields.map((assetClass, index) => {
        const item = {
          label: this.$t(this.translationPathCommon + assetClass),
          backgroundColor: this.aColors[index],
          data: []
        }
        for (const accountId in this.apiData.accounts) {
          switch (assetClass) {
            case 'equities':
              item.data.push(this.apiData.accounts[accountId].equity_amount)
              break
            default:
              prop = assetClass + '_amount'
              item.data.push(this.apiData.accounts[accountId][prop])
          }
        }
        datasets.push(item)
      })

      const labels = []
      for (const accountId in this.apiData.accounts) {
        labels.push(this.apiData.accounts[accountId].group_title + ' / ' + this.apiData.accounts[accountId].title)
      }

      return {
        labels: labels,
        datasets: datasets
      }
    },
    emptyGraphMessage () {
      return this.$t('incomes_expenses.accounts.view.no_transaction_no_chart')
    }
  }
}
</script>
