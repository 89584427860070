<template>
  <b-container fluid class="asset-allocation-report-table-wrapper px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'table_title')}} </h2>
          </template>

            <b-col class="col-md-8">
              <p>{{ $t(translationPath + 'description') }}</p>
            </b-col>

            <b-col class="px-0">
              <b-table class="spirecta-simple-table asset-allocation-report-table" show-empty hover responsive striped
                stacked="sm"
                :items="rows"
                :fields="fieldValues"
                :tbody-tr-class="trClass"
                :busy="busy"
                :empty-text="$t(translationPath + 'table_empty_text')"
              >
                <template v-slot:table-busy>
                  <loader/>
                </template>

                <template v-slot:cell(allocation)="row">
                  <template v-if="allocationFields.indexOf(row.item.allocation) !== -1">
                    {{ $t(translationPathCommon + row.value) }}
                  </template>
                  <template v-else>{{ $t('common.' + row.value) }}</template>
                </template>

                <template v-slot:cell(amount)="row">
                  <template v-if="currentCOA.locale">
                    {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true , 0) }}
                  </template>
                </template>

                <template v-slot:cell(percentage)="row">
                  {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false , 1) }} %
                </template>
              </b-table>
            </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AssetAllocationReportTableMixin from './AssetAllocationReportTableMixin'

export default {
  name: 'AssetAllocationReportTableTotal',
  mixins: [AssetAllocationReportTableMixin],
  data () {
    return {
      translationPath: 'reports.balance.asset_allocation_report.tab_total.',
      rows: [],
      busy: true
    }
  },
  methods: {
    makeRows () {
      this.busy = true
      this.rows = []
      if (this.apiData === null) {
        return false
      }
      if (this.apiData.length === 0) {
        this.busy = false
        return false
      }

      const rowEquity = { allocation: 'equities', amount: this.apiData.asset_class_sums.equities, percentage: this.apiData.asset_class_percentages.equities, type: 'row' }
      const rowFixedIncome = { allocation: 'fixed_income', amount: this.apiData.asset_class_sums.fixed_income, percentage: this.apiData.asset_class_percentages.fixed_income, type: 'row' }
      const rowCash = { allocation: 'cash', amount: this.apiData.asset_class_sums.cash, percentage: this.apiData.asset_class_percentages.cash, type: 'row' }
      const rowGold = { allocation: 'gold', amount: this.apiData.asset_class_sums.gold, percentage: this.apiData.asset_class_percentages.gold, type: 'row' }
      const rowAlternatives = { allocation: 'alternatives', amount: this.apiData.asset_class_sums.alternatives, percentage: this.apiData.asset_class_percentages.alternatives, type: 'row' }
      const rowOther = { allocation: 'other', amount: this.apiData.asset_class_sums.other, percentage: this.apiData.asset_class_percentages.other, type: 'row' }
      const rowNotDefined = { allocation: 'not_defined', amount: this.apiData.asset_class_sums.not_defined, percentage: this.apiData.asset_class_percentages.not_defined, type: 'row' }

      this.rows = [rowEquity, rowFixedIncome, rowCash, rowGold, rowOther, rowAlternatives, rowNotDefined]

      const rowTotal = { allocation: 'total', amount: 0, percentage: 0, type: 'row-total' }
      this.rows.map((row) => {
        rowTotal.amount += row.amount
        rowTotal.percentage += row.percentage
      })
      rowTotal.percentage = rowTotal.percentage.toFixed(0)
      this.rows.push(rowTotal)
      this.busy = false
    }
  }
}
</script>

<style lang="scss">
  .asset-allocation-report-table{
    thead tr th{border-top:0;}
  }
</style>
