import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  props: ['api-data'],
  data () {
    return {
      aColors: ['#FEC600', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c'],
      allocationFields: ['equities', 'fixed_income', 'cash', 'gold', 'alternatives', 'other', 'not_defined'],
      translationPathCommon: 'reports.balance.asset_allocation_report.common.',
      stackedChartOptions: {
        defaultFontFamily: 'Nunito',
        title: {
          display: false,
          text: 'Custom as Title',
          fontColor: '#231f20'
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: '#231f20'
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: true
            },
            ticks: {
              fontColor: '#231f20'
            }
          }],
          yAxes: [{
            stacked: true,
            display: true,
            gridLines: {
              display: true
            },
            ticks: {
              beginAtZero: true,
              fontColor: '#231f20',
              fontFamily: 'Nunito',
              callback: (value) => {
                return formatNumberToFull(value) + ' ' + (this.currencySymbol ? this.currencySymbol : '')
              }
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel).split(' ').join('') + ' ' + (this.currencySymbol ? this.currencySymbol : '')
            }
          }
        },
        pointDot: true,
        pointDotRadius: 5,
        pointDotStrokeWidth: 1,

        onAnimationComplete: null,
        scaleLineColor: 'rgba(0,0,0,.25)',

        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
