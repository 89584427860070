<template>
  <b-container fluid class="mb-5">
    <template v-if="!chartData">
      <empty-graph :message="emptyGraphMessage"/>
    </template>
    <template v-else>
      <treemap-nested v-if="chartData !== null" :data="chartData" />
    </template>
  </b-container>
</template>

<script>
import EmptyGraph from '@/components/common/EmptyGraph'
import AssetAllocationReportChartMixin from './AssetAllocationReportChartMixin'
import treemapNested from '@/components/d3charts/treemap-nested/treemap-nested.vue'

export default {
  name: 'AssetAllocationReportChartTotal',
  mixins: [AssetAllocationReportChartMixin],
  components: { treemapNested, EmptyGraph },
  computed: {
    chartData () {
      if (this.apiData === null || this.apiData.length === 0) {
        return null
      }

      const data = []
      this.allocationFields.map((assetClass, index) => {
        data.push({
          label: this.$t(this.translationPathCommon + assetClass),
          color: this.aColors[index],
          textColor: '#000',
          amount: this.apiData.asset_class_percentages[assetClass]
        })
      })
      return data
    },
    emptyGraphMessage () {
      return this.$t('incomes_expenses.accounts.view.no_transaction_no_chart')
    }
  }
}
</script>
