import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import Loader from '@/components/common/Loader'

export default {
  filters: { formatAmount },
  components: { Loader },
  props: ['api-data'],
  data () {
    return {
      allocationFields: ['equities', 'fixed_income', 'cash', 'gold', 'alternatives', 'other', 'not_defined'],
      translationPathCommon: 'reports.balance.asset_allocation_report.common.',
      busy: true
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    fieldValues () {
      return [
        { key: 'allocation', label: this.$t(this.translationPathCommon + 'allocation') },
        { key: 'percentage', label: this.$t(this.translationPathCommon + 'percentage'), class: 'text-right td-percentage' },
        { key: 'amount', label: this.$t(this.translationPathCommon + 'amount'), class: 'text-right td-amount' }
      ]
    }
  },
  methods: {
    trClass (item) {
      if (item && item.type === 'row-total') {
        return 'tr-total'
      } else if (item && item.type === 'row-subtotal') {
        return 'tr-subtotal'
      } else if (item && item.type === 'row-group') {
        return 'tr-group'
      } else if (item && item.type === 'row-account') {
        return 'tr-account'
      }
      return ''
    }
  },
  watch: {
    apiData: {
      deep: true,
      handler () {
        this.makeRows()
      }
    }
  },
  created () {
    this.makeRows()
  }
}
