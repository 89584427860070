<template>
  <b-container fluid class="asset-allocation-report-table-class-accounts px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'table_title')}} </h2>
          </template>

          <b-col class="col-md-8">
            <p>{{ $t(translationPath + 'description') }}</p>
          </b-col>

          <template v-for="(assetClass, index) in rows">
            <div :key="index" class="mt-4" :class="'table-wrap-' + assetClass.title">
              <b-col cols="12" class="table-content px-0">
                <b-table class="spirecta-simple-table income-accounts-table asset-allocation-table-account-groups" show-empty hover responsive striped
                  stacked="sm"
                  :items="assetClass.rows"
                  :fields="fieldValues"
                  :tbody-tr-class="trClass"
                  :busy="busy"
                  :empty-text="$t(translationPath + 'table_empty_text')"
                >
                  <template v-slot:table-busy>
                    <loader/>
                  </template>

                  <template v-slot:head(title)>
                    <h3>{{ $t(translationPathCommon + assetClass.title) }}</h3>
                  </template>

                  <template v-slot:cell(title)="row">
                    <template v-if="row.item.type === 'row'">
                      <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'" class="font-weight-normal">{{ row.value }}</b-link>
                    </template>
                    <template v-else>
                      {{ $t('common.' + row.value) }}
                    </template>
                  </template>

                  <template v-slot:cell(amount)="row">
                    <template v-if="currentCOA.locale">
                      {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true , 0) }}
                    </template>
                  </template>

                  <template v-slot:cell(class_percentage)="row">
                    {{ row.value }} %
                  </template>

                  <template v-slot:cell(total_percentage)="row">
                    {{ row.value }} %
                  </template>
                </b-table>
              </b-col>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AssetAllocationReportTableMixin from './AssetAllocationReportTableMixin'

export default {
  name: 'AssetAllocationReportTableClassAccounts',
  mixins: [AssetAllocationReportTableMixin],
  data () {
    return {
      translationPath: 'reports.balance.asset_allocation_report.tab_asset_class_accounts.',
      rows: [],
      busy: true
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title'), class: '' },
        { key: 'amount', label: this.$t(this.translationPathCommon + 'amount'), class: 'text-right td-amount' },
        { key: 'class_percentage', label: this.$t(this.translationPathCommon + 'class_percentage'), class: 'text-right td-percentage' },
        { key: 'total_percentage', label: this.$t(this.translationPathCommon + 'total_percentage'), class: 'text-right td-percentage' }
      ]
    }
  },
  methods: {
    makeRows () {
      this.busy = true
      this.rows = []
      if (this.apiData === null) {
        return false
      }
      if (this.apiData.length === 0) {
        this.busy = false
        return false
      }

      const totalRow = { title: 'total', amount: 0, total_percentage: 0, class_percentage: 0, type: 'row-total' }
      this.allocationFields.filter((assetClass) => this.apiData.asset_class_accounts[assetClass].length).map((assetClass) => {
        const item = {
          title: assetClass,
          rows: []
        }

        const subtotalRow = {
          title: 'subtotal',
          amount: 0,
          class_percentage: 0,
          total_percentage: 0,
          type: 'row-subtotal'
        }
        this.apiData.asset_class_accounts[assetClass].map((account) => {
          const row = {
            id: account.id,
            title: account.title,
            amount: assetClass === 'equities' ? account.equity_amount : account[assetClass + '_amount'],
            total_percentage: parseFloat(account['percentage_of_total_sum_' + assetClass]).toFixed(1),
            class_percentage: parseFloat(account['percentage_of_total_' + assetClass]).toFixed(1),
            type: 'row'
          }
          subtotalRow.amount += row.amount
          subtotalRow.class_percentage = 100
          subtotalRow.total_percentage = parseFloat(parseFloat(subtotalRow.total_percentage) + parseFloat(row.total_percentage)).toFixed(1)
          item.rows.push(row)
        })
        item.rows.push(subtotalRow)
        totalRow.amount = parseFloat(parseFloat(totalRow.amount) + parseFloat(subtotalRow.amount)).toFixed(1)
        totalRow.class_percentage = 100.0
        totalRow.total_percentage = 100.0

        this.rows.push(item)
      })

      this.rows.push({ title: 'total', rows: [totalRow] })
      this.busy = false
    }
  }
}
</script>

<style lang="scss">
  .asset-allocation-table-account-groups{
    thead tr th{vertical-align: bottom;padding-bottom:10px;border-top:0;font-size:90%;font-weight:400;}
  }
</style>
